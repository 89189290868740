'use strict';

export function initInScope($scope) {
    let currentIndex = 0,
        lightGallery = $scope.find('.js-lightbox'),
        playButton = $scope.find('.js-timelapse-start'),
        isPlaying = false,
        interval;


    lightGallery.on('onAfterAppendSubHtml.lg', (e) => {
        playButton = $('.js-timelapse-start');

        playButton.on('click', function(){
            if (isPlaying === true) {
                pause();
            } else {
                play();
                // $('#shape1 animate').attr({'to':'0,0 110,0 110,300 0,300','from':'0,0 150,75 150,225 0,300'})[0].beginElement();
                // $('#shape2 animate').attr({'to':'190,0 300,0 300,300 190,300','from':'150,75 300,150 300,150 150,225'})[0].beginElement();
            }
        });
    });

    let img = [];
    $.getJSON('/static/json/webcams.json', function(data){
        img = data;
    });

    function play() {
        isPlaying = true;
        interval = setInterval(showNextFrame, 500);
    }

    function pause() {
        isPlaying = false;
        clearInterval(interval);
        // $('#shape1 animate').attr({'from':'0,0 110,0 110,300 0,300','to':'0,0 150,75 150,225 0,300'})[0].beginElement();
        // $('#shape2 animate').attr({'from':'190,0 300,0 300,300 190,300','to':'150,75 300,150 300,150 150,225'})[0].beginElement();
    }

    function showNextFrame () {
        currentIndex++;
        if (img.length > currentIndex) {
            //load next image
            if(currentIndex+1 < img.length){
                var image = new Image();
                image.src = img[currentIndex+1];
            }

            // $('.js-timelapse-img').attr('src', img[currentIndex]);
            $('.lg-image').attr('src', img[currentIndex]);
        } else {
            currentIndex = 0;
            pause();
        }
    }

    lightGallery.on('onCloseAfter.lg', (e) => {
        //console.log('pause');
        pause();
        currentIndex = 0;
    });
}