"use strict";

export function getServicePermission(service) {
    console.log("Cookiebot.consent[" + service + "]: " + Cookiebot.consent[service]);

    if(Cookiebot.consent[service]) {
        return true;
    }else{
        return false;
    }
}

export function setServicePermission(service) {
    let consent = service;
    let preferences = consent === 'preferences';
    let statistics = consent === 'statistics';
    let marketing = consent === 'marketing';


    Cookiebot.submitCustomConsent(preferences, statistics, marketing);
    Cookiebot.changed = true;
}
