'use strict';

export function initInScope($scope) {
    let calcBtn = $scope.find('.js-get-costs__flight');

    let $mtow = $scope.find('.js-mtow'),
        $mtowHandling = $scope.find('.js-mtow-handling');

    $mtow.on('keyup', (e) => {
        if(e.target.value > 5000){
            $mtowHandling.addClass('d-none');
        }else{
            $mtowHandling.removeClass('d-none');
        }
    });

    calcBtn.on('click', (e) => {
        let parent = $(e.target).closest('.parkrechner'),
            fromDate = parent.find('.js-datepicker__input[data-date="from"]').datepicker('getDate'),
            fromTimeH = parent.find('.form-control--time[data-time="fromH"]').val(),
            fromTimeM = parent.find('.form-control--time[data-time="fromM"]').val(),
            toDate = parent.find('.js-datepicker__input[data-date="to"]').datepicker('getDate'),
            toTimeH = parent.find('.form-control--time[data-time="toH"]').val(),
            toTimeM = parent.find('.form-control--time[data-time="toM"]').val();

        let dateFromDate = new Date(fromDate);
        let dateToDate = new Date(toDate);
        dateFromDate.setHours(fromTimeH,fromTimeM,0);
        dateToDate.setHours(toTimeH,toTimeM,0);
        let diff = dateToDate.getTime() - dateFromDate.getTime();
        if(diff < 0){
            $scope.find('.js-calc-error').removeAttr('hidden')
            e.preventDefault()
            e.stopPropagation()
        }
    })
}