'use strict';

export function init() {
    let $affixContainer = $('.js-affix-navbar__placeholder'),
        $affixbar = $('.js-affix-navbar'),
        $offset = $affixbar.position().top;

    $(window).one('scroll', function () {
        $affixContainer.css('height', $('.js-affix-navbar').outerHeight()+'px')
    });


    affix($affixContainer, $affixbar, $offset);
    window.onscroll = function() {
        $('body').trigger('scrolling');
        affix($affixContainer, $affixbar, $offset);
    };
}

function affix($affixContainer, $affixbar, $offset) {
    if($(window).scrollTop() > $offset){
        $affixbar.addClass('is-affix')
    }else{
        $affixbar.removeClass('is-affix')
    }
}