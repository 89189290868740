'use strict';

export function initInScope($scope) {

    $scope.find('.js-dark-site').on('hidden.bs.modal', () => {
        sessionStorage.setItem('darksite', 'closed');
    });

    if (window.sessionStorage) {
        if (!sessionStorage.getItem('darksite')) {
            $scope.find('.js-dark-site').modal('show');
        }
    }
}