'use strict';
import Swiper from 'swiper'

export function initInScope($scope) {
    window.onload = function(){
        let swiper = new Swiper('.js-slider-vacation', {
            slidesPerView: 2.5,
            spaceBetween: 4,
            observer: true,
            observeParents: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                }
            }
        });
    }
}