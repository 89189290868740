'use strict';

export function initInScope($scope) {
    $scope.find('.js-open-hotspot').on('click', function(){
        console.log('click hotspot')
        let hotspot = $(this).parent();

        if(!hotspot.hasClass('is-active')){
            $(this).closest('.hotspot-map').find('.hotspot').each(function(){
                $(this).removeClass('is-active');
            });
            hotspot.addClass('is-active');
            $('body').on('click', bodyClickHandler);
        }else {
            hotspot.removeClass('is-active');
            $('body').off('click', bodyClickHandler);
        }
    });

    function bodyClickHandler (evt) {
        if ($(evt.target).parents('.hotspot').length < 1) {
            evt.preventDefault();
            $('.hotspot').removeClass('is-active');
            $('body').off('click', bodyClickHandler);
        }
    }
}