'use strict';

export function initInScope($scope) {

    let $switch = $scope.find('.js-flight-search__switch-btn');

    $switch.on('click', (e) => {
        let $searchFrom = $(e.delegateTarget).closest('.js-flight-search__switch')
                .find('.js-flight-search__input-from'),
            $searchTo = $(e.delegateTarget).closest('.js-flight-search__switch')
                .find('.js-flight-search__input-to');

        let tmp = $searchFrom.val();
        $searchFrom.val($searchTo.val())
        $searchTo.val(tmp)

        if($searchFrom.attr('readonly')){
            $searchFrom.removeAttr('readonly');
            $searchTo.attr('readonly', 'readonly');
        }else{
            $searchFrom.attr('readonly', 'readonly');
            $searchTo.removeAttr('readonly');
        }

        let placeholderTmp = $searchFrom.attr('placeholder');
        $searchFrom.attr('placeholder', $searchTo.attr('placeholder'))
        $searchTo.attr('placeholder', placeholderTmp)

    });
}