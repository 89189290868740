'use strict';

export function initInScope($scope) {
    let rechner = $scope.find('.js-parkrechner');
    $scope.find('.js-get-costs').on('click', function(){

        let $resultContainer = $scope.find('.parkrechner-results');

        if ($(window).width() < 767){
            $('html, body').animate({
                scrollTop: $resultContainer.offset().top - 50
            }, 500);
        }



        let parent = $(this).closest('.parkrechner'),
            fromDate = parent.find('.js-datepicker__input[data-date="from"]').datepicker('getDate'),
            fromTimeH = parent.find('.form-control--time[data-time="fromH"]').val(),
            fromTimeM = parent.find('.form-control--time[data-time="fromM"]').val(),
            toDate = parent.find('.js-datepicker__input[data-date="to"]').datepicker('getDate'),
            toTimeH = parent.find('.form-control--time[data-time="toH"]').val(),
            toTimeM = parent.find('.form-control--time[data-time="toM"]').val();

        let dateFromDate = new Date(fromDate);
        let dateToDate = new Date(toDate);

        dateFromDate.setHours(fromTimeH,fromTimeM,0);
        dateToDate.setHours(toTimeH,toTimeM,0);

        let diff = dateToDate.getTime() - dateFromDate.getTime();

        if(diff < 0){
            $scope.find('.js-calc-error').removeAttr('hidden')
            return;
        }

        let parkdauer = Math.round(diff / 60000); //get diff in minutes

        let calcDauer = diff / 1000;
        let calcD = Math.floor(calcDauer/86400);
        calcDauer -= calcD * 86400;
        let calcH = Math.floor(calcDauer/3600) % 24;
        calcDauer -= calcH * 3600;
        let calcM = Math.floor(calcDauer/60) % 60;

        $('.js-parkdauer').find('[data-park-calc="day"]').html(calcD);
        $('.js-parkdauer').find('[data-park-calc="hour"]').html(calcH);
        $('.js-parkdauer').find('[data-park-calc="min"]').html(calcM);

        $('.park-costs__price').each(function(){
            $(this).removeClass('is-not-possible');
        });


        fetch(rechner.data('costs'), {
            method: 'POST',
            headers: {
                'X-Requested-With' : 'XMLHttpRequest',
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
            }
        })
        .then((data) => {
            return data.json()
        }).then((data) => {
            let obj;
            for (let i = data.length-1; i >= 0; i--) {
                obj = data[i];
                if(parkdauer >= obj.dauer){
                    break;
                }
            }
            let priceL1 = $('.js-costs').find('[data-object="l1"]'),
                priceL2L3 = $('.js-costs').find('[data-object="l2l3"]'),
                priceL3 = $('.js-costs').find('[data-object="l3"]'),
                priceParkdeck = $('.js-costs').find('[data-object="parkdeck"]'),
                priceC1C2 = $('.js-costs').find('[data-object="c1c2"]');

            if(obj.l1 < 0){
                let dataText = priceL1.data('not-possible');
                priceL1.addClass('is-not-possible');
                obj.l1 = dataText;
            }
            if (obj.l2l3 < 0) {
                let dataText = priceL2L3.data('not-possible');
                priceL2L3.addClass('is-not-possible');
                obj.l2l3 = dataText;
            }
            if (obj.l3 < 0) {
                let dataText = priceL3.data('not-possible');
                priceL3.addClass('is-not-possible');
                obj.l3 = dataText;
            }
            if (obj.parkdeck < 0) {
                let dataText = priceParkdeck.data('not-possible');
                priceParkdeck.addClass('is-not-possible');
                obj.parkdeck = dataText;
            }
            if (obj.c1c2 < 0) {
                let dataText = priceC1C2.data('not-possible');
                priceC1C2.addClass('is-not-possible');
                obj.c1c2 = dataText;
            }

            priceL1.html(obj.l1);
            priceL2L3.html(obj.l2l3);
            priceL3.html(obj.l3);
            priceParkdeck.html(obj.parkdeck);
            priceC1C2.html(obj.c1c2);
        }).catch((error) => {
            /*Do error stuff*/
            console.error(error);
        });
    });
}