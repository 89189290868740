'use strict';

export function initInScope($scope) {

    let webcams = $scope.find('.js-reload-webcam');

    $(webcams).each(function(webcam){

        let $this = $(this);
        let imgSrc = $this.attr('src');

        $this.attr('src', imgSrc + '?' + new Date().getTime());
        $(webcams[webcam]).on('load', function(){
            console.log('on load');
            setTimeout(function(){
                console.log('on timeout');
                let imgSrc = $this.attr('src');
                $this.attr('src', addTimeParamToUrl(imgSrc));
                let $reload = $this.closest('.js-lightbox-reload');
                $reload.attr('href', addTimeParamToUrl(imgSrc));
            }, 5000);
        });

        let timeout;
        $this.closest('.js-lightbox').on('onSlideItemLoad.lg', (e, index, a,b,c,d) => {
            console.log('onSlideItemLoad.lg');
            timeout = setTimeout(function(){
                console.log('onSlideItemLoad.lg timeout');
                let $lgImage = $('.lg-image');
                $lgImage.attr('src', addTimeParamToUrl($lgImage.attr('src')));
            }, 5000);
        });
        $this.closest('.js-lightbox').on('onCloseAfter.lg', (e, index) => {
            clearTimeout(timeout);
        });
    });
}

function addTimeParamToUrl(url) {
    return url.indexOf('?t=') >= 0
        ? url.replace(/\?t=\d*/g, '?t=' + new Date().getTime())
        : url + '?t=' + new Date().getTime()
}