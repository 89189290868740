'use strict';

export function initInScope($scope) {
    let container = $scope.find('.js-alert-airport-news');

    if (!sessionStorage.getItem('airportNews')) {
        setTimeout(function(){
            container.removeAttr('hidden');
        }, 250);
    }

    $('body').on('click', '.js-alert-airport-news__close', function(e) {
        e.preventDefault();
        container.attr('hidden','hidden');
        sessionStorage.setItem('airportNews', 'closed');
    });

}