import {initAjaxFormMap, apiLoaded} from "@elements/ajax-form-map/ajax-form-google";
// import {showInfoBoxByMarker, closeInfoBoxByMarker} from "@elements/google-map";
import { showInfoBoxByMarker, closeInfoBoxByMarker} from './cookie-google-map';
import { loadGoogleMapsAPI } from "./cookie-google-map";
import { getServicePermission } from "./cookiebot-utils";

export function initInScope($scope) {
    let $googleMaps = $scope.find('.js-ajax-form-map__map');
    // loadGoogleMapsAPI();
    console.log("loadgooglemapsapi executed");

    if ($googleMaps && $googleMaps.length) {
        $googleMaps.each(function (index, element) {
            let $container = $(element);
            let $mapCanvas = $container.find('.js-ajax-form-map__map__canvas');


            // problem: apiLoaded does not get executed
            loadGoogleMapsAPI().then(function () {
                const poiStyles = {
                    default: {
                        default: {
                            url: '/static/build/img/map/marker.svg',
                            size: new google.maps.Size(30, 30),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(15, 15)
                        },
                        active: {
                            url: '/static/build/img/map/marker-active.svg',
                            size: new google.maps.Size(30, 30),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(15, 15)
                        }
                    },
                    green: {
                        default: {
                            url: '/static/build/img/map/marker-green.svg',
                            size: new google.maps.Size(20, 20),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(10, 10)
                        },
                        active: {
                            url: '/static/build/img/map/marker-green-active.svg',
                            size: new google.maps.Size(20, 20),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(10, 10)
                        }
                    },
                    red: {
                        default: {
                            url: '/static/build/img/map/marker-red.svg',
                            size: new google.maps.Size(20, 20),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(10, 10)
                        },
                        active: {
                            url: '/static/build/img/map/marker-red-active.svg',
                            size: new google.maps.Size(20, 20),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(10, 10)
                        }
                    },
                    yellow: {
                        default: {
                            url: '/static/build/img/map/marker-yellow.svg',
                            size: new google.maps.Size(20, 20),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(10, 10)
                        },
                        active: {
                            url: '/static/build/img/map/marker-yellow-active.svg',
                            size: new google.maps.Size(20, 20),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(10, 10)
                        }
                    },
                    orange: {
                        default: {
                            url: '/static/build/img/map/marker-orange.svg',
                            size: new google.maps.Size(20, 20),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(10, 10)
                        },
                        active: {
                            url: '/static/build/img/map/marker-orange-active.svg',
                            size: new google.maps.Size(20, 20),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(10, 10)
                        }
                    }
                };

                const infoBoxOptions = {
                    pixelOffset: new google.maps.Size(-50, 124),
                    boxStyle: {padding: "20px", /*, wdith: "340px", background: "#fff"*/},
                    boxClass: "cargo-map-infobox__container",
                    closeBoxURL: "/static/build/img/map/close.gif"
                };

                let clusteringOptions = {
                    default: {
                        styles: [{
                            height:60,
                            url: "/static/build/img/map/cluster.png",
                            width: 60,
                            textSize: 14,
                            textColor: "#FFF"
                        }]
                    },
                    active: {
                        styles: [{
                            height: 40,
                            url: "/static/build/img/map/cluster.png",
                            width: 40,
                            textSize: 14,
                            textColor: "#FFF"
                        }]
                    }
                };

                //let options = $container.data('google-map-options');
                let options = {
                    fullscreenControl: false
                }

                //init ajax form map
                let loadMap = true
                console.log("getServicePermission('marketing')", getServicePermission('marketing'))
                if(!getServicePermission('marketing')){
                    loadMap = false
                }

                loadMap && console.log("initializing google map!");
                loadMap && initAjaxFormMap($scope.find('.js-ajax-form-map'), undefined, {
                    element: $mapCanvas[0],
                    mapOptions: options,
                    poiStyles,
                    infoBoxOptions,
                    clustering: true,
                    clusteringOptions,
                    onActivateMarker: (marker, mapObj) => onActivateMarker($container, marker, mapObj),
                    onDeactivateMarker: (marker, mapObj) => onDeactivateMarker($container, marker, mapObj),
                });
            });
        });
    }
}


function onActivateMarker($container, marker, mapObj) {
    if(marker.detailInfoBoxUrl) {
        let infoBoxPromise = showInfoBoxByMarker(
            marker,
            `<div class="my-2">
                <div class="loading condensed" >
                    Loading...
                    <div class="loading__spinner">
                        <span class="icon icon-plane"></span>
                    </div>
                </div>
            </div>`,
            mapObj
        );

        let contentPromise = $.ajax(marker.detailInfoBoxUrl, {
            cache: true,
            dataType: 'html',
            headers: {Ajax: 1}
        }).catch(function (a, b, c) {
            console.error(a, b, c);
        });

        $.when(infoBoxPromise, contentPromise).then(function (infoBox, [infoBoxContent]) {
            if(infoBox.getVisible()) {
                // open the new info box with the new content only if the old one is still open
                infoBox.setContent(infoBoxContent);
            }
        });
    }
}

function onDeactivateMarker($container, marker, mapObj) {
    closeInfoBoxByMarker(marker, mapObj);
}

