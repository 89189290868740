'use strict';

export function initInScope($scope) {

    let $nav = $scope.find('.js-slider-grid-teaser__nav'),
        $navBtn = $nav.find('button'),
        $slider = $scope.find('.js-slider-grid-teaser'),
        $mobileDropdown = $nav.find('.js-dropdown-toggle');

    import('slick-carousel').then(() => {
        $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            fade: true,
            infinite: false
        });
    });


    $navBtn.on('click', (e) => {
        if($(e.delegateTarget).hasClass('js-dropdown-toggle')){
            return;
        }

        $navBtn.removeClass('active');
        $(e.delegateTarget).addClass('active');
        $slider.slick('slickGoTo', $(e.delegateTarget).data('slide'));
        $mobileDropdown.html($(e.delegateTarget).html());
    });

    $slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
        $navBtn.removeClass('active')
        let $activeBtn = $nav.find(`[data-slide='${currentSlide}']`);

        $activeBtn.addClass('active');

        $mobileDropdown.html($($activeBtn[1]).html());
    });
}