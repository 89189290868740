'use strict';
export function initInScope($scope) {

    let $slider = $scope.find('.js-slider-gallery');

    import('slick-carousel').then(function () {
        $slider.each(function () {
            let $container = $(this);
            let numTotalSlides = $container.find('.js-slider-gallery__slides > div').length;
            let $numTotalSlidesHTML = $container.find('.js-slider-counter__total');
            let $mainSlider = $container.find('.js-slider-gallery__slides');

            $numTotalSlidesHTML.html(numTotalSlides);

            $mainSlider.on('afterChange', function(event, slick, currentSlide, nextSlide){
                $mainSlider.closest('.js-slider-gallery').find('.js-slider-counter__current').html(currentSlide + 1);
            }).slick({
                draggable: true,
                adaptiveHeight: false,
                centerPadding: '20px',
                slidesToShow: 1,
                centerMode: true,
                dots: false,
                mobileFirst: true,
                infinite: false,
                prevArrow: '<button type="button" class="slick-prev text-primary slider-gallery slider-gallery--prev d-flex align-items-center justify-content-center slick-arrow" aria-label="Previous"><span class="icon icon-arrow_small-down icon-rotate-90"></span></button>',
                nextArrow: '<button type="button" class="slick-next text-primary slider-gallery slider-gallery--next d-flex align-items-center justify-content-center slick-arrow" aria-label="Next"><span class="icon icon-arrow_small-down icon-rotate-270"></span></button>',
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            centerPadding: '120px',
                        }
                    }
                ]
            });
        });
    });
}