'use strict';
export function initInScope($scope) {

    import('slick-carousel').then(() =>{
        $scope.find('.js-slider-blog').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            infinite: false,
            prevArrow: '.blog-detail-slider--prev',
            nextArrow: '.blog-detail-slider--next',
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });

        let $repaintElements = $scope.find('.js-slider-blog ').find('.js-repaint');
        for(let i = 0; i < $repaintElements.length; i++) {
            $repaintElements[i].style.display = 'none';
            $repaintElements[i].offsetHeight;
            $repaintElements[i].style.display = '';
        }
    });
}