export function initInScope($scope) {
    if($scope.find('.wysiwyg table').length){

        let table = $scope.find('.wysiwyg table');
        table.addClass('table');
        table.attr('border','0');
        table.attr('cellspacing','0');
        table.attr('cellpadding','0');
        table.width('100%');
        //table.wrap('<div class="table-responsive"></div>');
    }
}