"use strict";

import loadScript from 'load-script';

let scriptCache = {};

export default function (url) {
    if (!url.startsWith('http') && !url.startsWith('//')) {
        console.warn('\'loadScript\' should only be used for external resources. Use \'import\' for local files. \n Loaded url was \'' + url + '\'');

        if (process.env.CACHE_BUSTER) {
            url = '/cache-buster-' + process.env.CACHE_BUSTER + url;
        }
    }

    if (!scriptCache[url]) {
        scriptCache[url] = new Promise((resolve, reject) => (
            loadScript(url, {async: true, attrs: {'data-cookieconsent': 'ignore'}}, function (error, script) {
                if (error) {
                    reject(error);
                }
                else {
                    resolve(script);
                }
            })
        ));

        scriptCache[url].catch(function (errorMsg) {
            console.error('error loading script ' + url + '\n',  errorMsg);
        });
    }
    return scriptCache[url];
};

