import $ from 'jquery';

// import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/scrollspy';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

$('.js-tooltop').tooltip();
$('body').scrollspy({ target: '#content-jump-nav' })

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as airportNews from './scripts/airportNews';
app.modules.airportNews = airportNews.initInScope;

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as flightCalculator from './scripts/flightCalculator';
app.modules.flightCalculator = flightCalculator.initInScope;

import * as tableStyles from './scripts/tableStyles';
app.modules.tableStyles = tableStyles.initInScope;

import * as fileInput from '@elements/file-input';
app.modules.fileInput = fileInput.initInScope;

import * as datepicker from '@elements/datepicker';
app.modules.datepicker = datepicker.initInScope;
import * as datepickerRange from '@elements/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;
import * as floatingDatepicker from './scripts/floatingDatepicker';
app.modules.floatingDatepicker = floatingDatepicker.initInScope;

import * as sliderBlog from './scripts/sliderBlog';
app.modules.sliderBlog = sliderBlog.initInScope;

import * as fileuploadDelete from './scripts/fileupload-delete';
app.modules.fileuploadDelete = fileuploadDelete.initInScope;

import * as sliderVacation from './scripts/sliderVacation';
app.modules.sliderVacation = sliderVacation.initInScope;

import * as sliderGridTeaser from './scripts/sliderGridTeaser';
app.modules.sliderGridTeaser = sliderGridTeaser.initInScope;

import * as sliderContent from './scripts/sliderContent';
app.modules.sliderContent = sliderContent.initInScope;

import * as webcam from './scripts/webcam';
app.modules.webcam = webcam.initInScope;
import * as timeLapse from './scripts/timeLapse';
app.modules.timeLapse = timeLapse.initInScope;

import * as parkingcalculator from './scripts/parkingcalculator';
app.modules.parkingcalculator = parkingcalculator.initInScope;

import * as sliderBlogDetail from './scripts/sliderBlogDetail';
app.modules.sliderBlogDetail = sliderBlogDetail.initInScope;

import * as touchNav from '@elements/touch-nav';
app.modules.touchNav = touchNav.initInScope;

import * as affix from '@elements/affix';
app.modules.affix = affix.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as anchornav from '@elements/anchor-nav';
app.modules.anchornav = anchornav.initInScope;

import * as headerAffix from './scripts/headerAffix';
app.headerAffix = headerAffix.init;

import * as searchFlight from './scripts/searchFlight';
app.modules.searchFlight = searchFlight.initInScope;

import * as cargoMap from './scripts/cargoMap';
app.modules.cargoMap = cargoMap.initInScope;

import * as openHotSpots from './scripts/openHotSpots';
app.modules.openHotSpots = openHotSpots.initInScope;

import * as flightSearchSwitch from './scripts/flight-search-switch';
app.modules.flightSearchSwitch = flightSearchSwitch.initInScope;

import * as darksite from './scripts/darksite';
app.modules.darksite = darksite.initInScope;

import * as loadMore from '@elements/load-more';
app.modules.loadMore = loadMore.initInScope;

import * as menuAim from './scripts/menuAim';
app.modules.menuAim = menuAim.initInScope;
import * as nav from './scripts/nav';
app.nav = nav.init;

import * as ajaxForm from '@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.initInScope;

import * as googleMap from "@elements/google-map";
app.modules.googleMap = googleMap.initInScope;

import * as accessiway from './scripts/accessiway';
accessiway.init();

import * as conditionalForm from './scripts/conditional-form';
app.modules.conditionalForm = conditionalForm.initInScope;



import * as typeahead from '@elements/typeahead';
let typeaheadOptions = {
    hint: true,
    highlight: true,
    minLength: 2,
    autocompleteText: 'title'
};

const layouts = {
    headerLayout : (data, category) => {
        return ('<div class="typeahead-heading d-flex justify-content-between">'+
            '<a href="' + data.overviewUrl +'" class="typeahead-heading__text mb0">' +
            '<span class="typeahead-heading__icon text-primary ' + category.icon + '"></span><span>' + category.title + '</span></a>' +
            '</div>')
    },
    suggestionLayout : (data, category) => {

        const printImage = (imgUrl) => {
            return(
                '<div>' +
                '<img style="min-height: 90px; min-width: 90px; max-height: 90px; max-width: 90px" src="' + data.imgurl + '" alt="' + data.title + '"/>' +
                '</div>'
            )
        }

        if(category.name === 'product'){
            return (
                '<div class="d-flex ">' +
                (data.imgurl ? printImage(data.imgurl) : '') +
                '<div class="ml-3 d-flex flex-column justify-content-between ">' +
                '<div class="">' +
                '<p class="mb-0">' + data.title + '</p>' +
                '<small>' + data.text + '</small>' +
                '</div>' +
                '<div class="">' +
                (data.oldprice ? '<s class="text-muted">' + data.oldprice + '</s>' : '') +
                (data.oldprice ? '<span class="text-danger ml-2 font-family-bold">' + data.price + '</span>' : '<span>' + data.price + '</span>') +
                '</div>' +
                '</div>' +
                '</div>'
            )
        }else{
            return (
                '<div class="d-flex align-items-center"><div class="typeahead-suggestion">' + data.title + '</div>' +
                '<div class="text-muted ml-3">' + data.numOfResults + '</div></div>'
            )
        }
    },
    errorLayout : () => {
        return;
    }
};
app.modules.typeahead = typeahead.createInitInScope(typeaheadOptions , layouts);

import * as cookieConsentOverlays from "./scripts/cookie-consent-overlay";
app.modules.cookieConsentOverlays = cookieConsentOverlays.initInScope;

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);