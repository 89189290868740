'use strict';
export function initInScope($scope) {

    import('slick-carousel').then(() =>{
        $scope.find('.js-slider-blog').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: true,
            infinite: false,
            prevArrow: '<button type="button" class="slick-prev text-primary slider-blog--prev d-flex align-items-center justify-content-center" aria-label="Previous"><span class="icon icon-arrow_small-down icon-rotate-90"></span></button>',
            nextArrow: '<button type="button" class="slick-next text-primary slider-blog--next d-flex align-items-center justify-content-center" aria-label="Next"><span class="icon icon-arrow_small-down icon-rotate-270"></span></button>',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1.5,
                        slidesToScroll: 1,
                    }
                }
            ]
        });

        let $repaintElements = $scope.find('.js-slider-blog ').find('.js-repaint');
        for(let i = 0; i < $repaintElements.length; i++) {
            $repaintElements[i].style.display = 'none';
            $repaintElements[i].offsetHeight;
            $repaintElements[i].style.display = '';
        }
    });
}