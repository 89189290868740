"use strict";

import $ from "jquery";
import loadScript from "./cookie-load-script";

const defaultOptions = {
    center: {lat: 47.836249, lng: 13.0615383},
    zoom: 12,
    scrollwheel: false
};

// options -> mapObj
export default function initMap({
                                    element,
                                    mapOptions = {},
                                    pois = [],
                                    poiStyles = null,
                                    infoBoxOptions = {},
                                    clustering = true,
                                    clusteringOptions = {},
                                    onActivateMarker,
                                    onDeactivateMarker
                                } = {}) {
    mapOptions = {...defaultOptions, ...mapOptions};

    let map = new google.maps.Map(element, mapOptions);

    let clusteringPromise;
    if (clustering) {
        clusteringPromise = import("gmaps-marker-clusterer").then(function () {
            return new MarkerClusterer(
                map,
                [],
                clusteringOptions.default || clusteringOptions // support single object (only default styling) as well as cluster styles object
            );
        });
    }

    const mapObj = {
        map,
        marker: [],
        activeMarker: null,
        mapOptions,
        poiStyles,
        infoBoxOptions,
        clustering,
        clusteringPromise,
        clusteringOptions,
        onActivateMarker,
        onDeactivateMarker
    };

    $(element).data('google-map', mapObj);

    return mapObj;
}


/* General */
export function loadGoogleMapsAPI() {
    if (!_config.googleMapAPIKey) {
        return $.Deferred().reject(new Error('google map key is not set. Please set _config.googleMapAPIKey'));
    }

    return loadScript("https://maps.googleapis.com/maps/api/js?key=" + _config.googleMapAPIKey + "&language=" + _config.lang);
}

export function getMapObj($element) {
    if (!($element instanceof jQuery)) {
        $element = $($element);
    }

    return $element.data('google-map');
}


/* Marker */
export function setMarker(mapObj, pois) {
    removeAllMarker(mapObj);
    addMarker(mapObj, pois);

    centerMap(mapObj);
}

export function addMarker(mapObj, pois) {
    pois.map(poi => {
        let position = new google.maps.LatLng(poi.lat, poi.lng);
        let poiStyle;


        if (poi.poiStyle) {
            if (mapObj.poiStyles) {
                if (mapObj.poiStyles[poi.poiStyle]) {
                    poiStyle = mapObj.poiStyles[poi.poiStyle];
                } else {
                    poiStyle = mapObj.poiStyles.default;
                    console.error(`Could not find poi style '${poi.poiStyle}' in `, mapObj.poiStyles);
                }
            } else {
                console.error('Poi styles ar not set');
            }
        } else if (mapObj.poiStyles && mapObj.poiStyles.default) {
            poiStyle = mapObj.poiStyles.default;
        }


        let marker = new google.maps.Marker({
            map: mapObj.map,
            position: position,
            icon: poiStyle ? poiStyle.default : null,
            poiStyle: poiStyle,
            dataId: poi.id,
            detailInfo: poi.detailInfo,
            detailInfoBoxUrl: poi.detailInfoBoxUrl
        });

        if (mapObj.onActivateMarker && mapObj.onDeactivateMarker) {
            google.maps.event.addListener(marker, 'click', function () {
                if (mapObj.activeMarker === marker) {
                    deactivateMarker(marker, mapObj);
                } else {
                    activateMarker(marker, mapObj);
                }
            });
        }
        mapObj.marker.push(marker);
    });

    if (mapObj.clustering) {
        mapObj.clusteringPromise.then(cluster => cluster.addMarkers(mapObj.marker));
    }
}

export function removeAllMarker(mapObj) {
    if (mapObj.onDeactivateMarker) {
        mapObj.marker.forEach(x => mapObj.onDeactivateMarker(x, mapObj));
    }

    $.each(mapObj.marker, function (index, marker) {
        marker.setMap(null);
    });

    mapObj.marker = [];

    if (mapObj.clustering) {
        mapObj.clusteringPromise.then(cluster => cluster.clearMarkers());
    }
}


/* Active marker state */
export function activateMarker(marker, mapObj) {
    if (mapObj.activeMarker) {
        deactivateMarker(mapObj.activeMarker, mapObj);
    }

    mapObj.onActivateMarker(marker, mapObj);
    mapObj.activeMarker = marker;

    setActiveMarkerStyle(marker, mapObj);

    mapObj.map.panTo(marker.getPosition());
}

export function deactivateMarker(marker, mapObj) {
    mapObj.onDeactivateMarker(mapObj.activeMarker, mapObj);
    mapObj.activeMarker = null;

    setDefaultMarkerStyle(marker, mapObj);
}

export function setActiveMarkerStyle(marker, mapObj) {
    if (marker.poiStyle) {
        marker.setIcon(marker.poiStyle.active);
    }
}

export function setDefaultMarkerStyle(marker, mapObj) {
    if (marker.poiStyle) {
        marker.setIcon(marker.poiStyle.default);
    }
}

export function centerMap(mapObj) {
    google.maps.event.trigger(mapObj.map, 'resize');
    scrollToMarkers(mapObj.marker, mapObj);
}

export function scrollToMarkers (markers, mapObj) {
    let latLngs = markers.map(marker => marker.getPosition());

    // Zoom/center map to fit all marker
    if (latLngs.length === 0) {
        mapObj.map.setCenter(mapObj.mapOptions.center);
    } else if (latLngs.length === 1) {
        mapObj.map.setCenter(latLngs[0]);
    } else {
        let latLngBounds = new google.maps.LatLngBounds();
        for (let i = 0, pos; pos = latLngs[i]; i++) {
            latLngBounds.extend(pos);
        }
        mapObj.map.setCenter(latLngBounds.getCenter());
        mapObj.map.fitBounds(latLngBounds);
    }
}


/* Info boxes */
// google.maps.Marker, htmlString | domNode, MapObj -> Promise(() -> InfoBox)
export function showInfoBoxByMarker(marker, content, mapObj, infoBoxOptions = {}) {
    const defaultInfoBoxOptions = {
        alignBottom: true,
        pixelOffset: new google.maps.Size(-158, -50),
        boxStyle: {
            width: "300px",
            background: "#fff",
            padding: "20px"
        }
    };

    let options = {
        ...defaultInfoBoxOptions,
        ...mapObj.infoBoxOptions,
        ...infoBoxOptions,
        content
    };

    return import('google-maps-infobox').then(function ({InfoBox}) {
        closeInfoBoxByMarker(marker);
        marker.infoBox = new InfoBox(options);
        google.maps.event.addListener(marker.infoBox, "closeclick", function (e) {
            deactivateMarker(marker, mapObj);
        });
        marker.infoBox.open(marker.map, marker);

        return marker.infoBox;
    });
}

// google.maps.Marker, MapObj -> void
export function closeInfoBoxByMarker(marker, mapObj) {
    if (marker.infoBox) {
        marker.infoBox.close();
        marker.infoBox = null;
    }
}

export function setActiveClusterStyle(cluster, mapObj) {
    if (cluster && cluster.clusterIcon_) {
        cluster.clusterIcon_.setOptions({
            ...mapObj.clusteringOptions.active,
            styles_: mapObj.clusteringOptions.active.styles,
            textColor_: mapObj.clusteringOptions.active.styles.textColor,
        });
        cluster.updateIcon();
    }
}

export function setDefaultClusterStyle(cluster, mapObj) {
    if (cluster && cluster.clusterIcon_) {
        let options = mapObj.clusteringOptions.default || mapObj.clusteringOptions;

        cluster.clusterIcon_.setOptions({
            ...options,
            styles_: options.styles,
            textColor_: options.styles.textColor,
        });
        cluster.updateIcon();
    }

}