"use strict";

import {setServicePermission,getServicePermission} from "./cookiebot-utils";

export function initInScope($scope) {
    Cookiebot && Cookiebot.onaccept(function() { console.log("onaccept fired"); location.reload()})
    Cookiebot && Cookiebot.ondecline(function() { console.log("ondecline fired"); location.reload()})
    Cookiebot && window.addEventListener('CookiebotOnConsentReady', function() {
        console.log("cookiebot onconsentready fired");
        Cookiebot.changed && document.location.reload();
    })

    let $cookieConsentButtons = $('.js-consent__accept-btn');
    $cookieConsentButtons.each(function(){
        let $cookieConsentButton = $(this);
        let service = $cookieConsentButton.closest('.js-consent').data('consent-service');
        let type = $cookieConsentButton.closest('.js-consent').data('consent-type');

        if(type === 'googlemap') {
            let overlay =$cookieConsentButton.closest('.js-consent__overlay');
            let wrapper = $cookieConsentButton.closest('.js-consent');
            let mapFilter = wrapper.find('.js-google-map__filter-wrapper');

            if(getServicePermission('marketing')){
                if(overlay.length !== 0){
                    overlay.attr("hidden", "hidden");
                    mapFilter.removeClass("google-map__filter-wrapper--hidden");
                }
            } else {
                if(overlay.length !== 0){
                    overlay.attr("hidden", false);
                    mapFilter.addClass("google-map__filter-wrapper--hidden");
                }
                $cookieConsentButton.on('click', function(e){
                    setServicePermission('marketing');
                });
            }
        } else if(type === 'video'){
            let overlay =$cookieConsentButton.closest('.js-consent__overlay');
            let wrapper = $cookieConsentButton.closest('.js-consent');

            if(getServicePermission('marketing')){
                // iframe.prop('src', src);
                if(overlay.length !== 0){
                    overlay.attr("hidden", "hidden");
                }
            } else {
                if(overlay.length !== 0){
                    overlay.attr("hidden", false);
                }
                $cookieConsentButton.on('click', function(e){
                    setServicePermission('marketing');
                });
            }
        } else{
            $cookieConsentButton.on('click', function(e){
                setServicePermission('marketing');
            });
        }
    });
}