'use strict';

export function initInScope($scope) {
    let $deleteBtn = $scope.find('.js-file-input__delete');

    let $fileUpload = $scope.find('.js-file-input__upload');

    $fileUpload.on('change', (e) => {
        $(e.delegateTarget).closest('.js-file-input')
            .find('.js-file-input__delete')
            .toggleClass('d-none')
            .toggleClass('d-flex')
    });

    $deleteBtn.on('click', (e) => {
       e.stopPropagation();
       e.preventDefault();
        $(e.delegateTarget).toggleClass('d-none').toggleClass('d-flex');
        $(e.delegateTarget).closest('.js-file-input').find('.js-file-input__upload').val('')
        $(e.delegateTarget).closest('.js-file-input').find('.js-file-input__text').html('')
    });
}