'use strict';

import initModulesInScope from "@elements/init-modules-in-scope";
import fetch from '@elements/fetch'; // IE10 Polyfill
import formDataEntries from 'form-data-entries';
import 'url-search-params-polyfill'; // Edge Polyfill

export function initInScope($scope) {
    let $reset = $scope.find('.js-search-flight__reset'),
        $search = $scope.find('.js-search-flight__search'),
        $form = $scope.find('.js-search-flight'),
        $arrival = $scope.find('.js-arrival'),
        $departure = $scope.find('.js-departure');

    $reset.on('click', (e) => {
        $form.find('input').val('')
        $form.find('.form-group').removeClass('has-value')
        $form.find('input').removeClass('has-value')
        $form.find('select').val('')
    });


    const url = $form.data('action');

    let pendingRequest;

    $form.on('submit', (e) => {
        e.preventDefault();

        if(pendingRequest){
            pendingRequest.abort();
            pendingRequest = null;
        }

        showLoading();

        let body = new URLSearchParams(formDataEntries($form[0]));

        pendingRequest = fetch(url, {
            method: 'POST',
            body: body
        });

        pendingRequest.then((res) => {
            return res.clone().json()
        }).then((res) => {
            if(res.success){
                if(res.html){
                    $arrival.html(res.html["arrival"]);
                    $departure.html(res.html["departure"]);

                    initInScope($arrival);
                    initInScope($departure);

                    initModulesInScope($arrival);
                    initModulesInScope($departure);



                    hideLoading();
                }
            }
        }).finally(() => {
            hideLoading();
        })
    });

    let loading = $scope.find('.schedules .js-load-more__loading');

    function showLoading() {
        loading.removeAttr('hidden')
    }
    function hideLoading() {
        loading.attr('hidden', 'hidden')
    }
}