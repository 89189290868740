
export function initInScope($scope) {
    $scope.find('.js-conditional-form').each(function () {

        let $form = $(this);
        let $parts = $form.find('.js-conditional-form__part');
        let $inputs = $form.find('.js-conditional-form__input');

        $form.find('.js-conditional-form__hide-all').on('click', function() {
            $parts.attr('hidden', 'hidden');
        });

        $inputs.on('input', function () {
            let formData = $form.serializeArray();
            let $parts = $form.find('.js-conditional-form__part');

            $parts.attr('hidden', 'hidden');

            formData
                .filter(({name, value}) => !!value)
                .forEach(({name, value}) => {
                    let $currentParts = $parts.filter(`[data-name="${name}"]`);

                    $currentParts.filter(function (index, element) {
                        let minValue = parseFloat($(element).data('min-value'));
                        return minValue <= parseFloat(value);
                    }).attr('hidden', null);

                    $currentParts.filter(`[data-value="${value}"]`).attr('hidden', null);
                });
        });
    })
}

