

export function init() {

    initApplicationCollapse();
    function initApplicationCollapse() {
        const mobileBreak     = window.matchMedia('(max-width: 767px)');

        if (document.documentMode || /Edge/.test(navigator.userAgent) || (/iPad|iPhone|iPod/.test(navigator.userAgent) || navigator.userAgent.indexOf("Safari") !== -1 && !window.MSStream)){
            mobileBreak.addListener((e) => {
                if(e.matches){
                    initMobile();
                    // deactivateSubmenu();
                    // initMobile();
                }else{
                    // destroyMobile();
                    destroyMobile();
                }
            })
        }else{
            mobileBreak.addEventListener('change', (e) => {
                if(e.matches){
                    initMobile();
                    // deactivateSubmenu();
                    // initMobile();
                }else{
                    // destroyMobile();
                    destroyMobile();
                }
            })
        }
        $(mobileBreak).trigger('change')
    }

    if(matchMedia('(min-width: 768px)').matches){
        // initDesktop();
    }else{
        initMobile();
    }

    function destroyMobile() {
        $('.js-nav__link').off('click');
        $('.js-nav__close').off('click');
        $('.js-nav__toggle').off('click');
        $('.js-nav__go-back').off('click');

        $('.js-nav').removeClass('is-open')
        $('.js-nav').find('.is-open').removeClass('is-open')
    }

    function initMobile(){
        $('.js-nav__link').on('click', function (evt) {
            let $currentLink = $(this);
            let $nav = $currentLink.closest('.js-nav');
            let $navItems = $nav.find('.js-nav__item');
            let $navItem = $currentLink.closest('.js-nav__item');

            evt.preventDefault();

            if($navItem.hasClass('is-open')) {
                $nav.removeClass('is-open');
                $navItem.removeClass('is-open');
            } else {
                $nav.addClass('is-open');
                $navItem.addClass('is-open');
            }
        });

        $('.js-nav__close').on('click', function () {
            let $close = $(this);
            let $nav = $close.closest('.js-nav');
            let $navItems = $nav.find('.js-nav__item');
            $navItems.removeClass('is-open');
        });

        $('.js-nav__toggle').on('click', function () {
            let $close = $(this);
            let $nav = $close.closest('.js-nav');
            let $navItems = $nav.find('.js-nav__item');

            $nav.toggleClass('is-open');
            $navItems.removeClass('is-open');

            if ($nav.hasClass('is-open')) {
                $('html').addClass('is-scrolling-disabled');
            } else {
                $('html').removeClass('is-scrolling-disabled');
            }
        });


        $('.js-nav__go-back').on('click', function() {

            let $goBack = $(this);
            let $navItem = $goBack.closest('.js-nav__item');
            $navItem.removeClass('is-open');
        });
    }
}