import "@elements/menu-aim";

export function initInScope($scope) {

    let $menuAim = $scope.find('.js-menu-aim')


    initApplicationCollapse();
    function initApplicationCollapse() {
        const
            mobileBreak     = window.matchMedia('(max-width: 767px)');

        if (document.documentMode || /Edge/.test(navigator.userAgent) || (/iPad|iPhone|iPod/.test(navigator.userAgent) || navigator.userAgent.indexOf("Safari") !== -1 && !window.MSStream)){
            mobileBreak.addListener((e) => {
                if(e.matches){
                    destroyDesktop();
                }else{
                    initDesktop();
                }
            })
        }else{
            mobileBreak.addEventListener('change', (e) => {
                if(e.matches){
                    destroyDesktop();
                }else{
                    initDesktop();
                }
            })
        }
        $(mobileBreak).trigger('change')
    }

    if(matchMedia('(min-width: 768px)').matches){
        initDesktop();
    }


    function destroyDesktop() {
        $menuAim.menuAim('destroy');
    }

    function initDesktop() {

        $menuAim.menuAim({
            activate: activateSubmenu,  // fired on row activation
            deactivate: deactivateSubmenu, // fired on row deactivation
            exitMenu: exitMenu, // fired on menu deactivation,
            submenuDirection: "below"
        });

        function activateSubmenu(row) {
            $(row).find('.js-menu-aim__sub-menu').addClass('is-open')
        }

        function deactivateSubmenu(row) {
            $(row).find('.js-menu-aim__sub-menu').removeClass('is-open')
        }

        function exitMenu() {
            $scope.find('.js-menu-aim__sub-menu').removeClass('is-open');
        }
    }
}